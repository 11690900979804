.l-fest21-home-fold {
	position: relative;
	margin-bottom: var(--p-rythm-lg);
	height: 100vw;

	display: flex;
	justify-content: center;
	align-items: center;

	&--inner {
		transition: opacity 300ms ease-in-out;
		opacity: 0;
		&.gfx-loaded {
			opacity: 1;
		}

		position: relative;
		display: flex;
		justify-content: center;
	}
	&--logo {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		&-img {
			width: 100%;
			height: auto;
		}
	}
	&--background {
		z-index: 1;
		position: relative;
		canvas {
			display: block;
			height: auto;
			width: 100%;
			margin: 0 auto;
			transform: translateZ(0);
		}
	}

	.theme-a11y & {
		background: black;
		margin: 0 calc((100vw - var(--page-width)) / 2 * -1);
	}
}

@include from-to($bp-tiny, $bp-medium) {
	.l-fest21-home-fold {
		height: auto;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
}

@include from-md {
	.l-fest21-home-fold {
		height: auto;
		margin-bottom: var(--section-gap);
		&--logo {
			&-img {
				width: 100%;
				margin: 0 auto;
			}
		}
		&--background {
			height: calc(100vh - (2 * var(--header-height)));
			min-height: 500px;
			canvas {
				width: auto;
				height: 100%;
			}
		}
	}
}
