@use "@neonjungle/birdseed/styles";

@import "mixins/mixin-loader";
@import "config";

// Global
@import "global/bases";

$accent-color: #00aeff;
$accent-color-light: lighten($accent-color, 18%);
$dark-blue: #080c16;
$middle-blue: lighten($dark-blue, 18%);

:root {
	--theme-text-color: white;
	--theme-accent-color: #{$accent-color};
	--theme-accent-color-light: #{$accent-color-light};
	--theme-page-background-color: #000000;
	--theme-ui-background-color: #{$dark-blue};
	--theme-ui-background-color-light: #{$middle-blue};
}

@import "components/gfx-block";

@import "layouts/fest21-home-fold";
@import "layouts/tito-widget";
